import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import Item from 'components/contentBlocks/LinkList/Item';
import Marquee from 'components/Marquee';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import contentMargins from 'config/theme/contentMargins';
import { imageProp } from 'utils/proptypes/modules/mediaProps';
import styled from 'libs/styled';
import { transformLinkListItems } from 'utils/dataTransformers/contentBlocks';

const Div = styled('div')``;

const LinkList = ({ links = [], tag = '' }) => {
    if (links.length === 0) {
        return null;
    }

    // Transform links for Marquee
    const marqueeLinks = transformLinkListItems(links);

    return (
        <Div display="flex" flexDirection="column" justifyContent="center">
            {tag && (
                <Tag marginBottom={['24px', null, null, null, '48px']} textAlign="center">
                    {tag}
                </Tag>
            )}
            <Below
                breakpoint="desktopSm"
                render={() => (
                    <Div display="flex" flexWrap="wrap" gap="24px 16px" mx={contentMargins} justifyContent="center">
                        {links.map(item => (
                            <Item key={item.link?.label} image={item.image} link={item.link} />
                        ))}
                    </Div>
                )}
            />
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <Div display="flex" flexDirection="column" gap="48px">
                        {marqueeLinks.map((subArray, index) => (
                            <Marquee
                                pauseOnHover
                                innerStyles={{
                                    animationDirection: index % 2 ? 'reverse' : 'normal',
                                    display: 'flex',
                                    gap: '0 32px',
                                }}
                                key={index}
                                speed={30}
                            >
                                {subArray.map(item => (
                                    <Item image={item.image} key={item.link?.label} link={item.link} />
                                ))}
                            </Marquee>
                        ))}
                    </Div>
                )}
            />
        </Div>
    );
};

LinkList.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.exact({
            image: imageProp,
            link: PropTypes.exact({
                label: PropTypes.string,
                to: PropTypes.string,
            }),
        })
    ),
    tag: PropTypes.string.isRequired,
};

export default LinkList;
