interface Link {
    image: object;
    link: object;
}

/**
 *
 * Transform links for Marquee.
 *
 * @param links {Array} Array of links.
 * @example - [1,2,3,4,5,6,7,8,9]
 *
 * @returns {Array} - Array of array with links.
 * @example - [[1,2,3], [4,5,6], [7,8,9]]
 */

export const transformLinkListItems = (links: Link[] = []) => {
    const numberOfItems = links.length;
    const maxNumberPerRow = 3;
    const numberOfRows = Math.ceil(numberOfItems / maxNumberPerRow);
    const transformedLinks: Link[][] = [];

    for (let index = 0; index < numberOfRows; index++) {
        let sliceStart = index * maxNumberPerRow;
        let sliceEnd = sliceStart + maxNumberPerRow;

        /*
         * Edge case: We want to avoid to have a single item on the last row.
         * Ex: [[1,2,3], [4,5,6], [7]]
         * Return: [[1,2,3], [4,5], [6,7]]
         */

        // Add one less to second last row
        if (sliceEnd === numberOfItems - 1) {
            sliceEnd -= 1;
        }

        // Add last two items to last row
        if (sliceStart === numberOfItems - 1) {
            sliceStart -= 1;
            sliceEnd -= 2;
        }

        /*
         * End edge case.
         */

        transformedLinks.push(links.slice(sliceStart, sliceEnd));
    }

    return transformedLinks;
};
