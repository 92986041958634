import BackgroundImage from 'components/background/BackgroundImage';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const StyledLink = styled(Link)``;

// We use a div as wrapper here for solving this issue:
// https://grebban.slack.com/archives/C19H0E5A9/p1650621596888319
const Div = styled('div')`
    .link-list-heading:hover {
        color: var(--theme-color-active);
        transition: color ${transitions.primary};
    }
`;

const ImageWrapper = styled('div')``;

const Item = ({ image = {}, link = {}, ...rest }) => (
    <StyledLink
        alignItems="center"
        display="flex"
        gap={['4px', null, null, null, '8px']}
        key={link.label}
        to={link.to}
        {...rest}
    >
        <ImageWrapper aspectRatio={ratios.horizontal} width={['48px', null, null, null, '98px']}>
            <BackgroundImage
                position={image.backgroundPosition}
                query={[
                    { w: 144, q: 70 },
                    { w: 144, q: 70 },
                    { w: 144, q: 70 },
                    { w: 294, q: 70 },
                ]}
                size={image.backgroundSize}
                src={image.url}
                width="100%"
                height="100%"
            />
        </ImageWrapper>
        <Div>
            <Heading
                className="link-list-heading"
                fontKeys={[
                    'Value Serif/24',
                    null,
                    'Value Serif/40-scalable-tablet',
                    null,
                    'Value Serif/80-scalable-desktop',
                ]}
                whiteSpace="nowrap"
                lineHeight="125%" // Prevent clipped off text
            >
                {link.label}
            </Heading>
        </Div>
    </StyledLink>
);

Item.propTypes = {
    image: PropTypes.object,
    link: PropTypes.object,
};

export default Item;
