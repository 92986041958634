import LinkListBlock from 'libs/wordpress/content/cmsBlocks/LinkListBlock';
import React from 'react';
import moduleMargins from 'config/theme/moduleMargins';
import styled from 'libs/styled';

const Wrapper = styled('section')``;

const LinkListModule = ({ ...rest }) => (
    <Wrapper my={moduleMargins.lg}>
        <LinkListBlock {...rest} />
    </Wrapper>
);

export default LinkListModule;
