import LinkList from 'components/contentBlocks/LinkList';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformLink from 'libs/wordpress/utils/transformLink';

// Pure CMS data transformation before we send it to the "real" block!
const LinkListBlock = ({ content = {} }) => {
    const { links = [], tag = '' } = content;

    // Transform links data
    const transformedlinks = links.map(({ background_image: backgroundImage, link }) => ({
        link: transformLink(link),
        image: transformBackgroundImage(backgroundImage),
    }));

    return <LinkList links={transformedlinks} tag={decodeHTMLEntities(tag)} />;
};

LinkListBlock.propTypes = {
    content: PropTypes.shape({
        links: PropTypes.arrayOf(PropTypes.object),
        tag: PropTypes.string,
    }).isRequired,
};

export default LinkListBlock;
